const ValidationMessage = {
    is_require: 'Is required!',
    gst_valid: 'Please enter valid GSTIN!',
    gst_length: 'Gst number must contain 15 digit!',
    financial_year_prefix_valid: 'Financial year should not start with zero!',
    invoice_prefix_valid: 'Invalid Invoice Number ',
    invalid_postfix: 'Invalid {{postfix}} ',
    mn_not_valid: 'Mobile number is not valid!',
    valid_email: 'Must be a valid email!',
    invoice_date_invalid: 'Invalid date',
    captcha_not_valid: 'Please enter a valid captcha!',
    financial_year_invalid: 'Financial year must be in YYYY-YY!',
    pan_valid: 'Please enter valid PAN no.!',
    new_confirm_pass: 'New password and confirm passwords should be match!',
    valid_password: 'Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).',
    otp_min_max: 'OTP is not valid!',
    otp_req: 'Please enter OTP!',
    mobile_no_valid: 'Mobile number is not valid!',
    future_date_not_allow: 'Future date is not allowed!',
    enter_valid_value: 'Please enter valid value',
    hsn_number: 'Must between 4 to 8 digits are required!',
    hsn_more_then_5cr: 'For taxpayers with an Annual Aggregate Turnover of Rs. 5 crores, a min. 6-digit HSN code is compulsory.',
    invalid_export_type: 'Type of Export is not valid',
    invalid_invoice_type: 'Invoice type is not valid',
    enter_valid_type: 'Please enter valid Type',
    hsn_number: 'Must between 4 to 8 digits are required!',
    token_length: 'Token must contain 10 digit!',
    invalid_length: 'Characters Length must be up to',
    invalid_year: 'Invalid Financial year',
    shipping_date: 'Shipping date can not be lower than invoice date',
    invalid_taxable_value: 'Taxable value should be greater than tax amounts',
    invalid_month: 'Original month is not valid',
    composition_date: 'Taxpayer type is Composition for the selected date!',
    invalid_place_supply: 'Place of supply is not valid',
    amount_not_zero: 'Amount should not be zero',
    total_number_not_zero: 'Total Number should not be zero',
    sgst_cgst_eq_rate: 'SGST Rate and CGST Rate must be equal',
    sgst_cgst_eq_amt: 'SGST Amount and CGST Amount must be equal',
    negative_amount_not_allow: 'A negative value is not allowed for the amount.',
    cess_rate_not_valid: 'Cess Rate (%) never more than 100.',
    valid_portal_password: 'Password should be of 8 to 15 characters (where the minimum length is 8 characters and maximum length is 15 characters) which should comprise of at least one number, special character and letters( at least one upper and lower case)',
    invalid_unit_item: 'Item Unit (UoM) value is not valid for given hsn/sac code',
    invalid_quantity_item: 'Quantity is not valid for given hsn/sac code',
    rate_not_zero: 'Rate should not be zero',
    invalid_qty: 'Quantity is not valid',
    invalid_tex_item: 'Invalid tax applied on item',
    invalid_billing_gst: 'Billing GSTIN is not valid',
    invalid_end_month: 'End month must be greater than or equal to start month',
    invoice_date_out_of_range: 'Invoice date is Out Of Date Range',
    note_date_out_of_range: 'Note date is Out Of Date Range',
    bill_date_out_of_range: 'Bill date is Out Of Date Range',
    invalid_bill_number: 'Invalid Bill Number. Bill number must be 7 digit.',
    invoice_space: 'Space not allowed!',
    signup_valid_password: 'Please follow password pattern!',
    confirm_pass: 'Confirm password must match with password!',
    eco_gstin_already_exists_in_current_period: 'This ECO GSTIN already exists in the current period',
    same_gst_valid: 'and E-commerce Operator cannot be the same.',
    sup_rec_same_gst: 'Supplier and Recipient cannot be same'
}
export default ValidationMessage
export const notifyMessage = {
    subscription_expire: 'The subscription period is expired, Please subscribe now.',
    trial_subscription_expire: 'The free trial period is expired, Please subscribe now.',
    subscription_expire_staff: 'The subscription period is expired, Please contact your owner to buy a subscription',
    trial_subscription_expire_staff: 'The free trial period is expired, Please contact your owner to buy a subscription',
    otp_error_message: 'You are not allowed to save changes, first get OTP and complete the verification process!',
    data_delete_success: 'Data has been deleted successfully.',
    pending_connection_request: 'Your connection request has not been approved yet. Try again after some time!',
    declined_connection_request: 'Your Munim connector request is declined!',
    disconnected_connection_request: 'Your Munim connection is disconnected!',
    disconnected_connection: 'Your Munim connection has been disconnected successfully.',
    remove_munim_connection: 'Your Munim connection has been removed successfully.',
    removed_connection: 'Your Munim connection request has been removed successfully.',
    data_not_found: 'Data not found!',
    data_update_success: 'Data has been updated successfully.',
    challan_download_success: 'Challan has been downloaded successfully.',
    support_token_copied: 'Support token is copied to clipboard.',
    no_que_found: 'No question found',
    select_bank: 'Please select bank',
    failed_challan_payment: 'Opps! Payment was not processed.',
    successful_challan_payment: 'Congratulations! Your payment completed successfully. You can click on check status option and do further process.',
    reg_type_updated: 'Registration Updated successfully.',
    temporarily_disconnect: "Your munim connection is temporarily removed by the Owner, Please contact and ask them to enable your connection.",
    challan_created_success: 'Challan has been created successfully.',
    pass_change_success: 'Password has been changed successfully.',
    summary_generated_success: 'Summary Generated Successfully',
    reset_gstn_data_success: 'GSTN Data Reset Successfully',
    download_data_success: 'Data has been downloaded successfully.',
    downloaded_gstr2b_data: 'GSTR2B data already downloaded and saved for the selected date range.',
    downloaded_gstr2a_data: 'GSTR2A data already downloaded and saved for the selected date range.',
    document_pending_status: 'Status Updated Successfully',
    document_accept_status: 'Data Updated Successfully',
    data_not_available: 'Data not available for selected month',
    allow_access_success: 'You have successfully enabled API access for 30 days.',
    tds_tcs_file_success: 'TDS & TCS Received filed successfully.',
    tds_tcs_file_stop: 'Tds & Tcs received filing process is stop due to screen change.',
    gstr3b_not_avail: 'Gstr3b is not available for selected period.',
    gstr3b_file_success: 'Gstr3b filed successfully.',
    gstr3b_evc_process_stop: 'GSTR3B File with evc process has been stopped because screen changed.',
    otp_resend_success: 'Resend Otp Request Sent Successfully.',
    otp_popup_close: 'Otp Pop Up is closed before completing process.',
    err_something_wrong: "Something went wrong, please try again!",
    select_payment_type: 'Please select Payment Through Option.',
    payment_verify_close: 'Make payment verification pop up is close.',
    update_ledger_balance_success: 'Ledger balance updated successfully.',
    update_ledger_balance_falied: 'Ledger data not available.',
    cmp08_nil_file_success: 'Nil GST CMP-08 filed successfully.',
    cmp08_nil_evc_process_stop: 'Cmp08 nil GST File with evc process has been stopped.',
    subscription: 'Please subscribe, to perform this operation!',
    upload_file_two_Mb: 'Upload file size up to 2 MB',
    upload_file_hundred_kB: 'Upload file size up to 100 kB',
    invalid_file_type: 'Invalid file type. The type of the uploaded file should be .jpeg/.jpg/.png/.bmp',
    company_access: 'Your Company access is restricted',
    contact_to_owner: 'please contact to owner!',
    data_not_found_notify: 'Data not found.',
    select_atleast_on_company: 'Please give at least one company permission to user!',
    select_atleast_one_document_type: 'Please select atleast one document type!',
    file_success: 'filed successfully.',
    Compute_gstr2b_success: 'GSTR 2B recomputed successfully.'
}
export const popUpMessage = {
    discard_title: 'Unsaved changes!',
    discard_content: 'If you discard changes, you’ll delete any edits you made since you last saved.',
    cancel_content: 'Leaving this page will delete all unsaved changes.'
}
export const tooltipMessage = {
    edit: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    set_as_default: 'Set as default'


}
